<!--
 * @Descripttion: 
 * @version: 
 * @Author: hutian
 * @Date: 2021-03-10 17:03:20
 * @LastEditors: hutian
 * @LastEditTime: 2021-03-10 18:08:43
-->
<template>
    <a-modal :title="handle === 'add' ? '新增' : '编辑'" style="top: 8px;" :width="1000" v-model="visible" @ok="toSubmit" okText="提交" :maskClosable="false">
        <a-form-model ref="form" :model="rowData" :rules="formRule" layout="horizontal" :label-col="{span:3}" :wrapper-col="{span:21}">
            <a-form-model-item label="大区名称" prop="name">
                <a-input v-model="rowData.name" placeholder="大区名称"></a-input>
            </a-form-model-item>
            <a-form-model-item label="菱感担当" prop="userIdList">
                <a-select placeholder="请选择" v-model="rowData.userIdList" mode="multiple">
                    <a-select-option :value="item.userId" v-for="(item, index) in userList" :key="index">{{item.nickName}}</a-select-option>
                </a-select>
            </a-form-model-item>
            <a-form-model-item label="备注" prop="">
                <a-textarea v-model="rowData.remark" placeholder="备注"></a-textarea>
            </a-form-model-item>
        </a-form-model>
    </a-modal>
</template>

<script>
import { editMhiacArea, selectByIdMhiacArea, addMhiacArea } from '../api/MhiacAreaApi'
export default {
    data() {
        return {
            visible: false,
            handle: 'add',
            rowData: {},
            // 表单验证
            formRule: {
                name: [
                    { required: true, message: '请输入大区名称', trigger: 'blur' }
                ],
                remark: [
                    { required: false, message: '请输入备注', trigger: 'blur' }
                ],
            },
            userList: []
        }
    },
    methods: {
        /**
         * 获取行数据
         */
        setRowData(row, handle){
            this.handle = handle
            this.visible = true
            // 获取菱感担当数据
            this.axios.get('/api/base/system/manager/linkgapAssume').then(res => {
                if(res.code == 200) {
                    this.userList = res.body
                }
            })
            if(handle == 'edit') {
                this.axios.get('/api/firm/mhiac/mhiacArea/selectById/' + row.id).then(res => {
                    this.rowData = res.body
                })
            }
        },
        /**
         * 表单提交
         */
        toSubmit() {
            this.$refs.form.validate(async valid => {
                if (!valid) {
                    return
                }
                const res = this.handle === 'add' ? await addMhiacArea(this.rowData) : await editMhiacArea(this.rowData)
                if (res.code === 200) {
                    this.$notification.success({ message: res.message })
                    this.$emit('reload')
                    this.visible = false
                    this.rowData = {}
                } else {
                    this.$notification.error({ message: res.message })
                }
            })
        }
    },
    created() {

    },

}
</script>

<style lang="less" scoped>

</style>
